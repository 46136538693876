@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,800');

* {
    box-sizing: border-box;
    border: none;
    outline: none;
}

html {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif !important;
    width: 100vw;
    height: 100vh;
}

#root {
    padding: 0;
}
/* disable selecting (more native) */
div { 
 -webkit-user-select: none; /* Safari 3.1+ */
 -moz-user-select: none; /* Firefox 2+ */
 -ms-user-select: none; /* IE 10+ */
 user-select: none; /* Standard syntax */
 }
p {
    margin: 0;
}
button:focus {
    outline: none;
}