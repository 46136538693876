.weather-tile-container {
  padding: 30px;
  height: 56.25vh;

  &.weather-day {
    background: linear-gradient(to bottom, #006ABB, #169AFF);
  }

  &.weather-night {
    background: linear-gradient(to bottom, #0F5A80, #006ABB);
  }

  .portl-logo {
    position: absolute;
    right: 15px;
    top: 7px;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: -0.92px;
    color: white;
  }

  .today {
    .first-row {
      display: flex;
      justify-content: space-between;
    }

    .center {
      display: flex;
      align-items: center;
      margin-left: 20px;
      width: 150px;
    }

    .icon-image {
      font-size: 115px;
      color: white;
    }

    .date-text {
      font-size: 30px;
      color: #f7f6f7;
    }

    .flex {
      display: flex;
    }

    .temperature-text {
      font-size: 80px;
      color: #f7f6f7;
    }

    .celsius {
      font-size: 60px;
      color: #f7f6f7;
      margin: 5px 10px 0 0;
    }

    .medium-text {
      font-size: 25px;
      color: white;
      margin-top: 10px;
    }

    .tiny-celsius {
      font-size: 30px;
      color: #f7f6f7;
      margin-top: 4px
    }

    .hour-text {
      font-size: 55px;
      color: white;
    }

    .sky {
      display: flex;
      width: 170px;
      justify-content: center;
      text-align: center;
    }

    .weather-status {
      font-size: 24px;
      color: #f7f6f7;
      margin-top: 10px;
    }

    .characteristic-text {
      height: 22px;
      margin: 7px 25px 5px 0;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: #d6d6d6;
    }

    .characteristic-values-text {
      width: 119px;
      height: 29px;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: #fff;
    }
  }

  .horizontal-divider {
    height: 3px;
    margin: 48px 28px 3px 14px;
    background-color: #f7f6f7;
  }

  .next-days-container {
    display: flex;
    justify-content: space-between;

    .display-center {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex: 1;
    }

    .day {
      font-size: 22px;
      color: #f7f6f7;
    }

    .small-icon-image {
      font-size: 70px;
      color: white;
      margin-top: 20px;
    }

    .min-max-temp {
      display: flex;
      margin-left: 15px;
      align-items: center;
      flex-direction: column;

      .max-temperature {
        font-size: 45px;
        color: #f7f6f7;
        border-bottom: 2px solid white;
      }

      .min-temperature {
        font-size: 25px;
        color: #f7f6f7;
      }
    }

    .small-celsius {
      font-size: 45px;
      color: #f7f6f7;
      margin-top: 4px
    }
  }

  .vertical-divider {
    background-color: white;
    width: 3px;
    height: 150px;
  }

  .centered-loading {
    position: absolute;
    top: 220px;
    left: 440px;
  }
}

.weather-portrait {
  &.weather-tile-container {
    padding: 3vh;
    height: 100vw;

    .portl-logo {
      position: static;
      display: flex;
      justify-content: flex-end;
      font-size: 7vh;
      font-weight: 600;
      letter-spacing: -0.23vh;
    }

    .today {
      .icon-image {
        height: 41.8vh;
        width: auto;
        max-width: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(145deg) brightness(109%) contrast(102%)
      }

      .weather-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10vh;
        margin-right: 5vh;
      }

      .date-text {
        font-size: 7vh;
        text-align: center;
        margin-top: 1.2vh;
        line-height: 10vh;
      }

      .temperature-text {
        font-size: 19vh;
      }

      .celsius {
        font-size: 10vh;
        margin: 0.5vh 1vh 0 0;
      }

      .medium-text {
        font-size: 4.2vh;
      }

      .tiny-celsius {
        font-size: 5vh;
        color: #f7f6f7;
        margin: 0;
      }

      .hour-text {
        font-size: 8.33vh;
        text-align: center;
        line-height: 10vh;
        margin-top: 2vh;
      }

      .sky {
        width: initial;
        margin-top: 20px;
      }

      .weather-status {
        font-size: 4.5vh;
        margin: 0;
      }

      .characteristics-container {
        display: flex;
        margin-top: 13vh;
        padding: 0 2vh;
        justify-content: space-between;
      }

      .characteristic-text {
        height: initial;
        margin: 0 3vh 0 0;
        font-size: 3.5vh;
        line-height: 6vh;
      }

      .characteristic-values-text {
        width: initial;
        height: initial;
        font-size: 4vh;
        line-height: 6vh;
      }
    }

    .horizontal-divider {
      margin: 16vh auto 0 auto;
    }

    .next-days-container {

      .day {
        font-size: 3.2vh;
        color: #f7f6f7;
      }

      .next-day-item {
        padding-top: 2vh;
      }

      .border-separator {
        border-right: 3px solid #f7f6f7
      }

      .small-icon-image {
        height: 15.37vh;
        margin-top: 2vh;
        max-width: 16vh;
        width: auto;
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(145deg) brightness(109%) contrast(102%)
      }

      .min-max-temp {
        margin-top: 0;
        margin-left: 0;
        justify-content: center;
        flex-direction: row;

        .max-temperature {
          margin-top: 1vh;
          font-size: 6vh;
          border-bottom: none;
        }
      }

      .small-celsius {
        font-size: 5vh;
        margin-top: 0
      }

      .next-day-weather-status {
        font-size: 2vh;
        color: #F7F6F7;
      }
    }

    .centered-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
}
